tarteaucitron.init({
    "privacyUrl": "", /* Privacy policy url */

    "hashtag": "#tarteaucitron", /* Open the panel with this hashtag */
    "cookieName": "tarteaucitron", /* Cookie name */

    "orientation": "bottom", /* Banner position (top - bottom) */
    "showAlertSmall": true, /* Show the small banner on bottom right */
    "cookieslist": false, /* Show the cookie list */

    "adblocker": false, /* Show a Warning if an adblocker is detected */
    "AcceptAllCta": true, /* Show the accept all button when highPrivacy on */
    "highPrivacy": true, /* Disable auto consent */
    "handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */

    "removeCredit": true, /* Remove credit link */
    "moreInfoLink": true, /* Show more info link */
    "useExternalCss": true, /* If false, the tarteaucitron.css file will be loaded */

    //"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for multisite */

    "readmoreLink": "/mentions-legales" /* Change the default readmore link */
});
var tarteaucitronForceLanguage = 'fr';


tarteaucitron.user.matomoId = '1';
tarteaucitron.user.matomoHost = 'https://matomo.onixstud.io/';
(tarteaucitron.job = tarteaucitron.job || []).push('matomo');

/*tarteaucitron.user.gtagUa = 'UA-23174643-1';
tarteaucitron.user.gtagMore = function () {};
(tarteaucitron.job = tarteaucitron.job || []).push('gtag');
(tarteaucitron.job = tarteaucitron.job || []).push('youtube');*/
(tarteaucitron.job = tarteaucitron.job || []).push('vimeo');
//   (tarteaucitron.job = tarteaucitron.job || []).push('dailymotion');


// tarteaucitron.user.tawktoId = '5db0697a78ab74187a5b255c';
// (tarteaucitron.job = tarteaucitron.job || []).push('tawkto');
