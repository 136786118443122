$(document).ready(function () {

    var popupCenter = function (url, title, width, height) {
        var popupWidth = width || 526;
        var popupHeight = height || 640;
        var windowLeft = window.screenLeft || window.screenX;
        var windowTop = window.screenTop || window.screenY;
        var windowWidth = window.innerWidth || document.documentElement.clientWidth;
        var windowHeight = window.innerHeight || document.documentElement.clientHeight;
        var popupLeft = windowLeft + windowWidth / 2 - popupWidth / 2;
        var popupTop = windowTop + windowHeight / 2 - popupHeight / 2;
        var popup = window.open(url, title, 'scrollbars=yes, width=' + popupWidth + ', height=' + popupHeight + ', top=' + popupTop + ', left=' + popupLeft);
        popup.focus();
        return true;
    };

    $('.share_twitter').click(
        function () {
            var url = $(this).data('url');
            var shareUrl = "https://twitter.com/intent/tweet?text=" + encodeURIComponent(document.title) +
                "&via=OnixStudio" +
                "&url=" + encodeURIComponent(url);
            popupCenter(shareUrl, "Partager sur Twitter");
        }
    );

    $('.share_facebook').click(
        function () {
            var url = $(this).data('url');
            var shareUrl = "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(url);
            popupCenter(shareUrl, "Partager sur facebook");
        }
    );

    $('.share_linkedin').click(
        function () {
            var url = $(this).data('url');
            var shareUrl = "http://www.linkedin.com/shareArticle?mini=true&url=" + encodeURIComponent(url);
            popupCenter(shareUrl, "Partager sur Linkedin");
        }
    );

});

