require('./bootstrap');
require('./BootSideMenu');
require('./menu');
// require('./web');
require('./social');
require('./tartaucitron');
require('./jquery.scrolltop');
// require('./map');
require('./scripts');
require('./simple-lightbox');

/* ITYPED */
import { init } from 'ityped'

const oneElement = document.querySelector('#ityped')

if (oneElement) {
    init(oneElement, {
        showCursor: true,
        backDelay: 1500,
        strings: [' PHOTOGRAPHIER', ' INVENTER', ' MODELISER', ' PARTAGER', ' DEVELOPPER']
    });
}



/* LAZY LOAD */
import LazyLoad from "vanilla-lazyload";

// function logElementEvent(eventName, element) {
//     console.log(Date.now(), eventName, element.getAttribute("data-bg"));
// }

// var callback_loaded = function (element) {
//     logElementEvent("👍 LOADED", element);
// };

var LL = new LazyLoad({
    elements_selector: ".lazy",
    //callback_loaded: callback_loaded
});


