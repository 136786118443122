/* MENU */
$(document).ready(function () {
    $('#menu').BootSideMenu({
        side: "left",
        pushBody: false,
        width: "calc(25% + 4px)",
        duration: 250,
        remember:false,
        autoClose:true,
        closeOnClick:true,
        icons: {
            left: 'fas fa-times  fa-2x',
            right: 'fas fa-ellipsis-v  fa-2x',
            down: 'glyphicon glyphicon-chevron-down'
        }
    });
});
