$(document).ready(function () {


    //$.notify("Hello World");

    window.scrollTo(0, 0);


    // $('.ttp').tooltipster({
    //     theme: 'tooltipster-onix'
    // });
    $('a').hover(function(e){

        $(this).attr('data-title', $(this).attr('title'));
        $(this).removeAttr('title');

    },
    function(e){

        $(this).attr('title', $(this).attr('data-title'));

    });

    // SCROLLTOP
    $.scrolltop();

    // SQUARETHIS
    squareThis('.square');

    if ($('.a-project-carousel').length) {
        var owl = $('.a-project-carousel');
        owl.owlCarousel({
            smartSpeed: 750,
            dots: false,
            nav: true,
            //autoplay: true,
            //autoWidth:true,
            loop: true,
            lazyLoad: true,
            margin: 5,
            autoplayHoverPause: true,
            navText: ['<div class="arrow"><div class="arrow-top"></div><div class="arrow-bottom"></div></div>', '<div class="arrow"><div class="arrow-top"></div><div class="arrow-bottom"></div></div>'],
            items: 1,
            responsive: {
                0: {
                    nav: false,
                },
                900: {
                    nav: true,
                }
            }
        });
        owl.trigger('stop.owl.autoplay')
        $(window).bind('scroll', function (event) {
            if ($('.a-play').hasClass('animated')) {
                owl.trigger('play.owl.autoplay', [7000]);
            }
        });

        $('.project-carousel-3d .owl-next').on({
            mouseenter: function () {
                $('.project-carousel-3d').addClass('move-left');
            }, mouseleave: function () {
                $('.project-carousel-3d').removeClass('move-left');
            }
        });
        $('.project-carousel-3d .owl-prev').on({
            mouseenter: function () {
                $('.project-carousel-3d').addClass('move-right');
            }, mouseleave: function () {
                $('.project-carousel-3d').removeClass('move-right');
            }
        });
    }


    function squareThis(element, ratio, minLimit) {
        square(ratio, minLimit);

        window.addEventListener('resize', function (event) {
            square(ratio, minLimit);
        });

        function square(ratio, minLimit) {
            if (typeof (ratio) === "undefined") {
                ratio = 1;
            }
            if (typeof (minLimit) === "undefined") {
                minLimit = 0;
            }
            var viewportWidth = window.innerWidth;

            if (viewportWidth >= minLimit) {
                var newElementHeight = $(element).width() * ratio;
                $(element).height(newElementHeight);
                $('.squarex2').height(newElementHeight);
            }
            else {
                $(element).height('auto');
            }
        }
    }

});


/* FILTRES */

$('#filtres .filtre').click(function () {

    // on recupère la valeur du filtre
    value = $(this).data('filter');
    console.log(value);
    if (value == 'all') {
        // $(this).hide() ;
        $(this).removeClass('show');
    } else {
        //$('.filter-all').show() ;
        $('.filter-all').addClass('show');
    }
    // on enlève la classe active de l'ancien élément
    $('#filtres .filtre').removeClass('active');
    // on ajoute la classe active sur l'élément cliqué
    if (value != 'all') {
        $(this).addClass('active');
    }

    // pour chaque div
    $('#filterlist .project').each(function () {
        el = $(this);
        el.removeClass('hidden');
        // on teste si l'élément n'a pas la classe du filtre ou que l'utilisateur ne souhaite pas tout affiché
        if (el.data('filter').indexOf(value) == -1 && value != "all")
            el.addClass('hidden');
    });
    $('#filterlist .pline').each(function () {
        el = $(this);
        el.removeClass('hidden');
        // on teste si l'élément n'a pas la classe du filtre ou que l'utilisateur ne souhaite pas tout affiché
        if (el.data('filter').indexOf(value) == -1 && value != "all")
            el.addClass('hidden');
    });

});

// $(function () {
//     $('[data-toggle="tooltip"]').tooltip()
// })
// $('.capturer').hover(
//     function () {
//         alert('ok') ;
//         $(this).text("Photographie");
//     },
//     function () {
//         $(this).text("Capturer");
//     },
// );
// $('.creer').hover(
//     function () {
//         $(this).text("Archiviz");
//     },
//     function () {
//         $(this).text("Créer");
//     },
// );
// $('.partager').hover(
//     function () {
//         $(this).text("Webdesign");
//     },
//     function () {
//         $(this).text("Partager");
//     },
// );

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});


$('#contactForm').on('submit',function(event){
    event.preventDefault();

    nom = $('#nom').val();
    prenom = $('#prenom').val();
    email = $('#email').val();
    telephone = $('#telephone').val();
    societe = $('#societe').val();
    email = $('#email').val();
    message = $('#message').val();
    project = $('#project').val();

    $.ajax({
        type:"POST",
        url: "/contact",
        data:{
            nom:nom,
            prenom:prenom,
            email:email,
            telephone:telephone,
            societe:societe,
            message:message,
            project:project,
        },
        success:function(response){
            //console.log(response);
            $("#contactForm")[0].reset();

            $.notify({
                message:"Votre message a bien été envoyé !"
            },{
                //position : "static",
                type:'success',
                placement: {
                    from: "top",
                    align: "center"
                },
                allow_dismiss: false,
                animate: {
                    enter: 'animated fadeInDown',
                    exit: 'animated fadeOutUp'
                },
                timer: 2000
            });

        },
    });
});


// $.notify({
// 	// options
// 	message: 'Hello World'
// },{
// 	// settings
// 	type: 'danger'
// });
