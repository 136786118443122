
//window._ = require('lodash');


window.Popper = require('popper.js').default;
window.$ = window.jQuery = require('jquery');

window.ityped = require('ityped') ;

// window.masonry = require('masonry-layout') ;
window.owl = require('owl.carousel') ;
//window.simplelightbox = require('simplelightbox') ;

// window.bootstrap = require('bootstrap') ;
window.tooltipster = require('tooltipster') ;
window.bootstrapnotify = require('bootstrap-notify') ;
/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

