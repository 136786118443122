(function($, window, document) {
  'use strict';

  // main function
  $.fn.scrolltop = function(options) {
    if (!$.data(document.body, 'scrolltop')) {
      $.data(document.body, 'scrolltop', true);
        $.fn.scrolltop.init(options);
    }
  };

  // default options
  $.fn.scrolltop.defaults = {
    elemName: 'scrolltop',
    scrollDistance: 300,
    scrollSpeed: 300,
    animationSpeed: 200
  };

  // init function
  $.fn.scrolltop.init = function(options) {
    var o = $.extend({}, $.fn.scrolltop.defaults, options),
        $self;

    // create element
    $self = $('<a/>', {
      id: o.elemName,
      href: '#top',
      style: 'display:none',
    }).html('<i class="fas fa-arrow-up"></i>');

    $self.appendTo('body');

    // scroll event
    $(window).on('scroll', function() {
      if ($(window).scrollTop() > o.scrollDistance) {
        $self.fadeIn(o.animationSpeed);
        $('.scroll').fadeOut(o.animationSpeed);
      } else {
        $self.fadeOut(o.animationSpeed);
        $('.scroll').fadeIn(o.animationSpeed);
      }
    });

    // click event
    $self.click(function(e) {
      e.preventDefault();

      $('html, body').stop().animate({
        scrollTop: 0
      }, o.scrollSpeed, 'linear');
    });
  };

  $.scrolltop = $.fn.scrolltop;

})(jQuery, window, document);
